<template>
  <div>
    <h1 class="white--text">Paramètres de votre Bar</h1>
    <img
      v-if="img_exist"
      class="img_menu pl-5"
      :src="'data:image;base64,' + img"
    />
    <div class="d-flex white--text">
      <v-file-input
      dark
        color="success"
        show-size
        v-model="file"
        class="file_input_img pt-12"
        label="Choisissez votre menu/carte"
      ></v-file-input>
      <div class="pt-15 pl-3">
        <v-btn small @click="save_file" color="success">Sauvegarder</v-btn>
      </div>
      <div class="pt-15 pl-4">
        <v-btn small @click="delete_file" color="error">Supprimer</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      file: null,
      img: null,
      img_exist: false,
    };
  },
  methods: {
    save_file() {
      // const file = new FormData();
      // file.append("carte", this.file);
      // let object = {
      //   carte: file,
      //   user: this.$store.state.id,
      // };
      const formData = new FormData();
      formData.append("carte", this.file);
      formData.append("user", this.$store.state.id);

      axios
        .post(this.$store.state.url + "/upload", formData)
        .then((response) => {
          if (response.data.code == 200) {
            location.reload();
            this.img_exist = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_file() {
      axios
        .get(this.$store.state.url + "/get_picture", {
          params: {
            user: this.$store.state.id,
          },
        })
        .then((response) => {
          if (response.data.code != 400) {
            this.img = response.data;
            this.img_exist = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delete_file() {
      axios
        .delete(this.$store.state.url + "/delete_picture", {
          params: {
            user: this.$store.state.id,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (!this.$store.state.login) {
      this.$router.push("/notFound");
    } else {
      this.get_file();
    }
  },
};
</script>

<style scoped>
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 30%;
  text-align: left;
}
.img_menu {
  width: 20em;
}
</style>